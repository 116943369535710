import React from "react";
import { Link } from "react-router-dom";

import "../../../../assets/styles/commenStyle/FeaturesSection.css";
import "../../../../assets/styles/footer/frontend-builder.css";

const MoreFeaturesSection = ({ data }) => {
  return (
    <>
      <div className="DigitalCommerceImg-main-container6">
        <p className="feature-headings6">{data?.Featcherheading}</p>

        <div className="DigitalCommerce-sub-cont0">
          {data?.LearnMoreFeature?.map((item, index) => (
            <div className="DigitalCommerce-sub-cont0-left">
              <img
                className="DigitalCommerce-sub-cont0-left-img"
                src={item.OrderManagementImg}
                alt="dxy"
              />
              <div className="featcher-left-main-cont0">
                <p className="featcher-plateform11">{item.Plateform}</p>
                <p
                  onClick={() =>
                    window.scrollTo({
                      top: 0,
                      left: 0,
                      behavior: "smooth",
                    })
                  }
                  className="featcher-left-headings"
                >
                  <Link to={`/${item.FeatcherLeftHead}`}>
                    {item.FeatcherLeftHead}
                  </Link>
                </p>
                <div>
                  <img
                    src={item.ArrowRightUpLine}
                    alt="arrow"
                    className="ArrowPosition6"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default MoreFeaturesSection;
