import React from "react";
import Navbar from "../../../shared/building-blocks/organisms/Navbar";
import Footer from "../../../shared/building-blocks/organisms/Footer";

import { DigitalCommerceData } from "../../../adapters/digital-commerce/digital-commerce";
import Herosections from "../../../shared/building-blocks/molecules/Product-Components/Herosections";
import Gallary from "../../../shared/building-blocks/molecules/Product-Components/Gallary";
import OtherFeatures from "../../../shared/building-blocks/molecules/Product-Components/AllFeatchers";
import MoreFeaturesSection from "../../../shared/building-blocks/molecules/Product-Components/MoreFeatures";

const DigitalCommerce = () => {
  return (
    <>
      <Navbar />

      <Herosections data={DigitalCommerceData} name={"DC"} />

      {DigitalCommerceData?.Gallary.map((item, index) => (
        <Gallary data={item} name={"DC"} type={index % 2 == 0 ? "TI" : "IT"} />
      ))}

      <OtherFeatures data={DigitalCommerceData} name={"DC"} />
      <MoreFeaturesSection data={DigitalCommerceData} name={"DC"} />

      <Footer />
    </>
  );
};

export default DigitalCommerce;
