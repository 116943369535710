import {
    HexagonImg,
    ArrowRightUpLine,
    B2cSolutionIcon1,
    B2cSolutionIcon2,
    B2cSolutionIcon3,
    B2cSolutionIcon4,
    OmnichannelMainImg,OmnichannelGalleryImg1,OmnichannelGalleryImg2,OmnichannelGalleryImg3,OmnichannelGalleryIcon1,OmnichannelGalleryIcon2,OmnichannelGalleryIcon3,OmnichannelFIcon1,
    OmnichannelFIcon2,OmnichannelFIcon3,FeatcherBackground
  } from "../../assets/images/Index";
  
  export const OmnichannelData = {
    HeroSection: {
      left: {
        title: "Omnichannel solution",
        heading: "One View, One Experience - All Channels",
        discription:
          "Provide a Global, real time view of your products to your customers. At the same time, tailor their experience across multiple sales channels.",
        buttonText: "Request a demo",
        arrowUpIcon: ArrowRightUpLine,
      },
  
      right: {
        imgUrl: OmnichannelMainImg,
      },
    },
  
    Gallary: [
      {
        left: {
          HexagonImg: HexagonImg,
          icon: OmnichannelGalleryIcon1,
          heading: "Implement endless aisle",
          discription:
            "Extended range of products to customers beyond the physical limitations of a brick-and-mortar store with in-store sales apps.",
        },
        right: {
          imgUrl: OmnichannelGalleryImg1,
        },
      },
      {
        left: {
          HexagonImg: HexagonImg,
          icon: OmnichannelGalleryIcon2,
          heading: "Track real-time product availability",
          discription:
            "Connect online storefronts with physical stores and warehouses thereby allowing to manage inventory more effectively.",
        },
        right: {
          imgUrl: OmnichannelGalleryImg2,
        },
      },
      {
        left: {
          HexagonImg: HexagonImg,
          icon: OmnichannelGalleryIcon3,
          heading: "Capture consumer insights",
          discription:
            "Collect data on customer behavior and preferences across multiple channels, providing valuable insights for marketing and sales strategies.",
        },
        right: {
          imgUrl: OmnichannelGalleryImg3,
        },
      },
    ],
  
    otherFeatures: {
      mainHeading: "Additional features",
      HexagonImg: HexagonImg,
      FeatcherBackground :FeatcherBackground,
      allFeatures: [
        {
          icon: OmnichannelFIcon1,
          heading: "Synchronize cart items across channels",
          description:
            "“Allow your customers to start shipping on one channel, and then continue their shopping experience on another channel, without losing saved items in the cart.",
        },
        {
          icon: OmnichannelFIcon2,
          heading: "Ready for the future",
          description:
            "Become flexible and adaptable for integrating new channels like Social media, AR, VR, Conversational commerce, and etcetra",
        },
        {
          icon: OmnichannelFIcon3,
          heading: "Connect to aggregators",
          description:
            "Increase visibility by connecting to marketplaces and aggregators like Amazon, Alibaba and so on to reach wider audience.",
        },
      ],
    },
  
    Featcherheading: "Explore more Solutions",
    LearnMoreSolution: [
      {
        Solution: "Marketplace solution",
        FeatcherLeftHead: "Intermediate between multiple buyers and sellers",
        OrderManagementImg: B2cSolutionIcon1,
        ArrowRightUpLine: ArrowRightUpLine,
      },
      {
        Solution: "D2C Commerce",
        FeatcherLeftHead: "Sell products directly to customers without intermediaries",
        OrderManagementImg: B2cSolutionIcon2,
        ArrowRightUpLine: ArrowRightUpLine,
      },
      {
        Solution: "Omnichannel solution",
        FeatcherLeftHead: "Sell products seamlessly through multiple channels",
        OrderManagementImg: B2cSolutionIcon3,
        ArrowRightUpLine: ArrowRightUpLine,
      },
      {
        Solution: "B2B Commerce",
        FeatcherLeftHead: "Sell products or services to other companies",
        OrderManagementImg: B2cSolutionIcon4,
        ArrowRightUpLine: ArrowRightUpLine,
      },
    ],
  };
  