import {Code, Dashboard, Hexagon, Links, Logistics, Pointer, Stack, Tree } from "../../assets/images/Index";
export const FeaturesSectionData = {
    title: "FEATURES",
    heading: "Everything you need to own your customers and their experience.",
    subHeading:"Capabilities that elevate performance",
    featuresList:[
        {
            icon: Stack,
            title: "Ease through operations",
            description: "Utilize a modular, headless, API-first approach to commerce that can be tailored to fit any business model.",
            link: "Explore Commerce engine" 
        },
        {
            icon: Dashboard,
            title: "Deliver a custom user experience",
            description: "Manage website content easily, create dynamic user experiences, and drive traffic and conversions.",
            link: "Explore Content Management" 
        },
        {
            icon: Code,
            title: "Enrich product data",
            description: "Keep accurate product information, boost productivity, and improve customer experience.",
            link: "Explore Product Information Management" 
        },
        {
            icon: Tree,
            title: "Organise and deliver unique shopping experiences",
            description: "Simplify shopping with advanced inheritance techniques that manage diverse product categorization.",
            link: "Explore Catalog Management" 
        },
        {
            icon: Logistics,
            title: "Streamline operations and logistics",
            description: "Boost order accuracy and speed with real-time updates, optimized processes, and security.",
            link: "Explore Order Management" 
        },
        // {
        //     icon: Links,
        //     title: "Handle the difficulties of supply chain with ease",
        //     description: "Manage distribution channels and efficiently with flexible grouping, and personalized quotes",
        //     link: "Explore DMS" 
        // },
    ],
    pointerIcon:Pointer,
    Hexagon
}