import {heroSectionImage, mainFeature1, mainFeature2, mainFeature3, mainFeatureIcon1, mainFeatureIcon2,mainFeatureIcon3, otherFeatureIcon1,otherFeatureIcon2,otherFeatureIcon3, Pointer,HexagonImg,ArrowRightUpLine,FrontEndBuilder,OrderManagementImg, FeatcherLeftImg, FeatcherRightImg} from "../../assets/images/Index";
export const frontendBuilderData = {


    HeroSection : {
        left : {
         title:"Front end experience builder",
         heading :"Build online storefronts effortlessly",
         discription:"Gain the ability to setup pages without intervention, using no-code building blocks.",
         buttonText: "Request a demo",
         arrowUpIcon :ArrowRightUpLine,
        },
 
        right : {
           imgUrl :heroSectionImage
        }
     },

 // gallary sections 
  Gallary:[
    {
        left :{
            HexagonImg : HexagonImg,
            icon : mainFeatureIcon1,
            heading:"Customize and make pages for future",
            discription : "Get ready for special occasions-Create fresh pages or revamp existing designs or modify your entire website with a seasonal theme, and even schedule it for publishing later.",
        },
        right :{
            imgUrl : mainFeature1,
        }
    },
    {
        left :{
            HexagonImg : HexagonImg,
            icon : mainFeatureIcon2,
            heading: "Bring device level customization",
            discription : "Create themes that are specific to channels and devices to tailor the customer experience and optimize SEO for each channel.",
        },
        right :{
            imgUrl : mainFeature2,
        }
    },
    {
        left :{
            HexagonImg : HexagonImg,
            icon : mainFeatureIcon3,
            heading: "Reach a wider audience",
            discription :"Configure multiple languages for geo-specific storefronts and tap new markets, improve credibility, and boost conversions.",
        },
        right :{
            imgUrl : mainFeature3,
        }
    },
  ],


    Pointer,
    mainFeatures:[
        {
            icon: mainFeatureIcon1,
            heading: "Customize and make pages for future",
            description: "Get ready for special occasions by creating fresh pages or revamping existing design and modify entire website with a seasonal theme and schedule it for publishing later.",
            sectionImage: mainFeature1
        },{
            icon: mainFeatureIcon2,
            heading: "Bring device level customization",
            description: "Make themes that are specific to channels and devices. Tailor customer experience and improvise SEO for each channel.",
            sectionImage: mainFeature2
        },{
            icon: mainFeatureIcon3,
            heading: "Reach wider audience",
            description: "Configure multiple languages to geo-specific storefronts and tap new markets, increase credibility, and boost conversions.",
            sectionImage: mainFeature3
        }
    ],
    otherFeatures: {
        mainHeading: "Additional features",
        HexagonImg :HexagonImg,
        allFeatures:[
            {
                icon: otherFeatureIcon1,
                heading: "Offer convenient checkouts",
                description: "Eliminate the need to create user accounts. Allow users to have a risk-free, smooth, and swift shopping experience.",   
            },{
                icon: otherFeatureIcon2,
                heading: "Increase Compatibility",
                description: "Enable users to shop from any browser, regardless of screen size, with a fully responsive and compatible design for mobiles, tablets and PC's.",   
            },{
                icon: otherFeatureIcon3,
                heading: "Improve navigation and visibility",
                description: "Create special pages to promote festive offers or specific brand categories, and gain a better understanding of customer behavior.",   
            }
        ]
    },


    Featcherheading : "Explore More",
    LearnMoreFeature :[
        {
            Plateform : "PLATFORM", 
            FeatcherLeftHead :"Order Management System",
            OrderManagementImg : OrderManagementImg,
            ArrowRightUpLine :ArrowRightUpLine,
        },
        {
            Plateform : "PLATFORM", 
            FeatcherLeftHead :"Front End Experience Builder",
            OrderManagementImg : FrontEndBuilder,
            ArrowRightUpLine :ArrowRightUpLine,
        },
        {
            Plateform : "PLATFORM", 
            FeatcherLeftHead :"Catalog Management",
            OrderManagementImg : FeatcherLeftImg,
            ArrowRightUpLine :ArrowRightUpLine,
        }, 
        {
            Plateform : "PLATFORM", 
            FeatcherLeftHead :"Product Information Management",
            OrderManagementImg : FeatcherRightImg,
            ArrowRightUpLine :ArrowRightUpLine,
        }
    ]
}