// styles 
import { Box, Text } from "@chakra-ui/react"
import { IoIosArrowDown } from 'react-icons/io';

import "../../../../assets/styles/building-blocks/atoms/special-content.css"
import { ReadMore } from "./read-more";
export const SpecialContent =({text})=>{
    return (
        <Box className="content-container">
            <Text>{text.heading}</Text>
            <Text>{text.description}</Text>
            <ReadMore/>
        </Box>
    )
}