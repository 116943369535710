import { Box, Button, Image, Text } from '@chakra-ui/react';
import { BiArrowBack } from 'react-icons/bi';
import { BsLinkedin } from 'react-icons/bs';
import { FiArrowUpRight } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
// styles 
import "../../assets/styles/bio/bio.css";
import Footer from '../../shared/building-blocks/organisms/Footer';
import Navbar from '../../shared/building-blocks/organisms/Navbar';
import { Linkdin } from '../../assets/images/Index';
export const Bio = ({ card }) => {
    const navigate = useNavigate()
    return (
        <Box>
            <Navbar />
            <Box className='bio-container'>
                <button onClick={() => {
                    navigate(-1)
                    window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: "smooth",
                    })
                }} >
                    <BiArrowBack />
                    Back
                </button>
                <Box>
                    <Image src={card.profileImg} />
                    <Box className="details">
                        <Box>
                            <Text>{card.name}</Text>
                            <a href={card.linkedin}><img src={Linkdin} /></a>
                        </Box>
                        <Text>{card.designation}</Text>
                        {/* <a href={card.linkedin}><button><BsLinkedin color="white" /> Linkedin <FiArrowUpRight color="white" /> </button></a> */}
                    </Box>
                    <Text className="description">{card.about} <br /> <br /> {card.extension}</Text>
                </Box>
            </Box>
            <Footer />
        </Box>
    )
}