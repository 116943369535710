import { Box } from '@chakra-ui/react'
import React from 'react'
import Footer from '../../shared/building-blocks/organisms/Footer'
import Navbar from '../../shared/building-blocks/organisms/Navbar'

const ContactUs = () => {
  return (
    <div>
        <Navbar/>
            <Box className=''>

            </Box>
        <Footer/>
    </div>
  )
}

export default ContactUs