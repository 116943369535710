import React, { useEffect, useState } from "react";
import "../../assets/styles/carousel/carousel.css";
import { aboutusData } from "../../adapters/Index";
const VerticalCarousel = ({ children, autoRotateTime = 4000 }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleClick = (index) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      const newIndex = (activeIndex + 1) % children.length;
      setActiveIndex(newIndex);
    }, autoRotateTime);

    return () => clearInterval(timer);
  }, [activeIndex, children.length, autoRotateTime]);
  console.log(children);
  return (
    <div className="carousel-container">
      <div className="carousel-buttons">
        <p>OUR HISTORY</p>
        {children.map((child, index) => (
          <div
            key={index}
            className={index === activeIndex ? "active" : ""}
            onClick={() => handleClick(index)}
          > <img src={aboutusData.historySection.comb}/>
            {child.props.children.split("#")[0]}
          </div>
        ))}
      </div>
      <div className="carousel-content">
        {children.map((child, index) => (
          <div
            key={index}
            className={`carousel-item ${
              index === activeIndex ? "active" : ""
            }`}
          >
            {child.props.children.split("#")[1]}
          </div>
        ))}
      </div>
    </div>
  );
};

export default VerticalCarousel;
