import React from "react";
import Navbar from "../../../shared/building-blocks/organisms/Navbar";
import Footer from "../../../shared/building-blocks/organisms/Footer";
import Herosections from "../../../shared/building-blocks/molecules/Product-Components/Herosections";
import { OrderManagementSystemData } from "../../../adapters/order-management-system/order-management-system";
import Gallary from "../../../shared/building-blocks/molecules/Product-Components/Gallary";
import OtherFeatures from "../../../shared/building-blocks/molecules/Product-Components/AllFeatchers";
import MoreFeaturesSection from "../../../shared/building-blocks/molecules/Product-Components/MoreFeatures";
const OrderManagementSystem = () => {
  return (
    <>
      <Navbar />

      <Herosections data={OrderManagementSystemData} name="OMS" />
      {OrderManagementSystemData?.Gallary.map((item, index) => (
        <Gallary data={item} name={"OMS"} type={index % 2 == 0 ? "TI" : "IT"} />
      ))}

      <OtherFeatures data={OrderManagementSystemData} name={"OMS"} />
      <MoreFeaturesSection data={OrderManagementSystemData} name={"OMS"} />
      <Footer />
    </>
  );
};

export default OrderManagementSystem;
