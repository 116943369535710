import {HexagonImg,SupplychainImg,OrderManagementImg1,FeatcherRightImg,FeatcherLeftImg,ArrowRightUpLine ,OparateGraphImg ,FlexibleOrderImg,AudienceImg ,InformationImg,OmsIcon1,OmsIcon2,OmsIcon3,OmsIcon4,OmsIcon5,OmsFeacherIcon1,OmsFeacherIcon2,OmsFeacherIcon3, OrderManagementImg, FrontEndBuilder} from "../../assets/images/Index";

export const OrderManagementSystemData = { 
    // hero section 
    HeroSection : {
        left : {
         title:"Order Management System",
         heading :"Streamline operations and fulfill from anywhere",
         discription:"Boost profit margins by configuring and managing multiple channels with refined fulfilment strategies.",
         buttonText: "Request a demo",
         arrowUpIcon :ArrowRightUpLine,
        },
 
        right : {
           imgUrl :OrderManagementImg1
        }
     },

   // gallary sections
   Gallary :[
    {
        left :{
            HexagonImg : HexagonImg,
            icon : OmsIcon1,
            heading:  "Operate and manage from one place",
            discription :"Efficiently handle multiple stores and all business operations with a single portal that consolidates orders and inventory from all channels.",
        },
        right :{
            imgUrl :OparateGraphImg,
        }
    },
    {
        left :{
            HexagonImg : HexagonImg,
            icon : OmsIcon2,
            heading:  "Offer flexible order fulfilment and never miss a customer",
            discription :"Extend inventory from one store to many through endless aisle and offer options for store pickup, doorstep delivery, and multi-store fulfilment.",
        },
        right :{
            imgUrl :FlexibleOrderImg,
        }
    },
    {
        left :{
            HexagonImg : HexagonImg,
            icon : OmsIcon3,
            heading:  "Deliver anywhere accurately",
            discription :"Set up multiple delivery regions through location mapping, pincodes, and country codes with options to configure shipping rates based on distance, region and cart value.",
        },
        right :{
            imgUrl :AudienceImg,
        }
    },
    {
        left :{
            HexagonImg : HexagonImg,
            icon : OmsIcon4,
            heading:  "Centralize inventory information",
            discription :"Have a single, up-to-date view of inventory across channel-make informed decisions about stock levels and reduce overstocking or stock shortages.",
        },
        right :{
            imgUrl :InformationImg,
        }
    },
    {
        left :{
            HexagonImg : HexagonImg,
            icon : OmsIcon5,
            heading:"Optimize supply chain",
            discription :"Reduce shipping costs and achieve cost effective fulfilment every time by utilizing an efficient order orchestration algorithm that calculates the best approach to delivery.",
        },
        right :{
            imgUrl :SupplychainImg,
        }
    },
   ],
    
   // all featcher section

    otherFeatures: {
        mainHeading: "Additional features",
        HexagonImg :HexagonImg,
        allFeatures:[
            {
                icon: OmsFeacherIcon1,
                heading: "Improve order accuracy",
                description: "Reduce returns and exchanges by using a robust picking and packing solution that prompts the picker to scan barcodes, preventing them from selecting the wrong product from the aisles.",   
            },{
                icon: OmsFeacherIcon2,
                heading: "Operate company owned delivery fleet",
                description: "Optimize delivery trips and routes for your in-house delivery crew with Honebi rider application.",   
            },{
                icon: OmsFeacherIcon3,
                heading: "Take full control of order processing",
                description: "Track the operational efficiency of the fulfilment team by maintaining (SLAs) and utilizing automated trigger mechanisms for order processing.",   
            }
        ]
    },

    Featcherheading : "Explore More",
    LearnMoreFeature :[
        {
            Plateform : "PLATFORM", 
            FeatcherLeftHead :"Order Management System",
            OrderManagementImg : OrderManagementImg,
            ArrowRightUpLine :ArrowRightUpLine,
        },
        {
            Plateform : "PLATFORM", 
            FeatcherLeftHead :"Front End Experience Builder",
            OrderManagementImg : FrontEndBuilder,
            ArrowRightUpLine :ArrowRightUpLine,
        },
        {
            Plateform : "PLATFORM", 
            FeatcherLeftHead :"Catalog Management",
            OrderManagementImg : FeatcherLeftImg,
            ArrowRightUpLine :ArrowRightUpLine,
        }, 
        {
            Plateform : "PLATFORM", 
            FeatcherLeftHead :"Product Information Management",
            OrderManagementImg : FeatcherRightImg,
            ArrowRightUpLine :ArrowRightUpLine,
        }
    ]
}