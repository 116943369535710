import React from 'react'
import "../../assets/styles/homePage/StoriesSection.css"
import { StoriesData } from '../../adapters/stories/Stories'
import { HexagonFill } from '../../assets/images/Index'
const Stories = () => {
    const hexagonStyle = {
        position: "absolute",
        top: 0,
        transform: "translate(-250%,-50%)"
    }
    const hexagonFill={
        position: "absolute",
        top: 0,
        transform: "translate(500%,100%)"
    }
  return (
    <div id='stories-container'>
        <img src={StoriesData.HexagonIcon} className="hexagonStyle" style={hexagonStyle} alt="" />
        <img src={StoriesData.HexagonFill} className="hexagonFill" style={hexagonFill} alt="" />
        <p className='section-name' >{StoriesData.title}</p>
        <p className='section-heading'>{ StoriesData.heading }</p>
        { StoriesData.stories.map((story, index)=>{
            if(index%2===0){
                return(
                    <div key={story.id} className='left-story-section'>
                        <img src={story.storyImg} alt={story.title} />
                        <div className='text-section'>
                            <p className='content-title'> { story.title } </p>
                            <div className="more-details">
                                <img src={StoriesData.Pointer} alt={story.title} />
                                <p className='story-description'>{story.description}</p>
                            </div>
                            <p className='story-details'>{story.details}</p>
                        </div>
                    </div>
                )
            }else{
                return(
                    <div key={story.id} className='right-story-section'>
                        <div className='text-section'>
                            <p className='content-title'> { story.title } </p>
                            <div className="more-details">
                                <img src={StoriesData.Pointer} alt={story.title} />
                                <p className='story-description'>{story.description}</p>
                            </div>
                            <p className='story-details'>{story.details}</p>
                        </div>
                        <img src={story.storyImg} alt={story.title} />
                    </div>
                )
            }
        }) }
    </div>
  )
}

export default Stories;