import React from "react";
import Herosections from "../../../shared/building-blocks/molecules/Product-Components/Herosections";
import Footer from "../../../shared/building-blocks/organisms/Footer";
import Navbar from "../../../shared/building-blocks/organisms/Navbar";

import { CatelogManagementData } from "../../../adapters/catalog-management/Catalog-management";
import Gallary from "../../../shared/building-blocks/molecules/Product-Components/Gallary";
import OtherFeatures from "../../../shared/building-blocks/molecules/Product-Components/AllFeatchers";
import MoreFeaturesSection from "../../../shared/building-blocks/molecules/Product-Components/MoreFeatures";

const Catelog = () => {
  return (
    <>
      <Navbar />

      <Herosections data={CatelogManagementData} name="CM" />

      {CatelogManagementData?.Gallary.map((item, index) => (
        <Gallary data={item} name={"CM"} type={index % 2 == 0 ? "TI" : "IT"} />
      ))}

      <OtherFeatures data={CatelogManagementData} name={"CM"} />
      <MoreFeaturesSection data={CatelogManagementData} name={"CM"} />

      <Footer />
    </>
  );
};

export default Catelog;
