import React from "react";
import Herosections from "../../shared/building-blocks/molecules/Product-Components/Herosections";
import Footer from "../../shared/building-blocks/organisms/Footer";
import Navbar from "../../shared/building-blocks/organisms/Navbar";
import { D2cData } from "../../adapters/d2c-commerce/d2c-commerce";

import Gallary from "../../shared/building-blocks/molecules/Product-Components/Gallary";
import OtherFeatures from "../../shared/building-blocks/molecules/Product-Components/AllFeatchers";
import LearnMoreSolutions from "../../shared/building-blocks/molecules/Solutions-components/LearnMoreSolutions";


const D2CPage = () => {
  return (
    <>
       <Navbar />

<Herosections data={D2cData} name="D2C" />

{D2cData?.Gallary.map((item, index) => (
  <Gallary data={item} name={"D2C"} type={index % 2 == 0 ? "TI" : "IT"} />
))}

<OtherFeatures data={D2cData} name={"D2C"} />
<LearnMoreSolutions data={D2cData} name={"D2C"} />
<Footer />
    </>
  )
}

export default D2CPage;