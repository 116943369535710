import React from 'react'
import "../../assets/styles/homePage/newsletter.css";

const NewsLetter = () => {
  return (
    <div id='newsletter-container'>
        <p className='section-title'>NEWSLETTER</p>
        <p className='section-heading'>Sign up for the Honebi newsletter</p>
        <div className='form-section' >
            <div className='email-section'>
                <input type="text" placeholder='Enter your email address' />
                {/* <p>*Please enter a valid email iD</p> */}
            </div>
            <div className='name-section'><input type="text" placeholder='Full name'/></div>
        </div>
        <div className='permission-section'>
            <input type="checkbox" />
            <p>By providing my Personal Data, 
                including name and contact information 
                to Honebi and its affiliates I agree 
                to be contacted for marketing purposes.
                Read the 
                <label htmlFor="">Terms and conditions</label> here.</p>
        </div>
        <div className='sign-me-up'><button>Sign me up</button></div>
    </div>
  )
}

export default NewsLetter