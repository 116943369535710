import "../ThemeVariables.css";
import styled from "styled-components";

export const GallarySec = styled.div`
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  display: flex;
  justify-content: space-around;
  font-family: var(--font-family-inter);
  margin-top: 9%;
  margin-left: 11%;
  margin-right: 11%;
  margin-bottom: 11%;
  flex-direction: ${(props) => (props.type === "IT" ? "row-reverse" : "row")};

  @media only screen and (min-width: 0px) and (max-width: 600px) {
    flex-direction: column-reverse;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 11%;
  }

  @media only screen and (min-width: 600px) and (max-width: 1000px) {
  }
`;

export const LeftSec = styled.div`
  display: flex;
  width: 34%;
  @media only screen and (min-width: 0px) and (max-width: 600px) {
    width: 90%;
  }
`;
export const RightSec = styled.div`
  @media only screen and (min-width: 0px) and (max-width: 600px) {
    padding-bottom: 50px;
  }

  @media only screen and (min-width: 600px) and (max-width: 1000px) {
  }
`;

export const LeftSecBox1 = styled.div`
  width: 20%;
  margin-top: 75px;

  @media only screen and (min-width: 0px) and (max-width: 600px) {
    padding-bottom: 150px;
    width: 30%;
  }
`;
export const LeftSecBox2 = styled.div``;

export const Titles = styled.p`
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600)
    var(--unnamed-font-size-38) / 54px var(--unnamed-font-family-inter);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-191f23);
  text-align: left;
  font: normal normal 600 38px/54px Inter;
  letter-spacing: 0px;
  color: #394146;
  opacity: 1;
  margin-top: 18px;
`;

export const Discriptions = styled.p`
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) var(--unnamed-font-size-18) /
    var(--unnamed-line-spacing-30) var(--unnamed-font-family-inter);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-75797b);
  text-align: left;
  font: normal normal normal 18px/30px Inter;
  letter-spacing: 0px;
  color: #75797b;
  opacity: 1;
  margin-top: 45px;
`;
