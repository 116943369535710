import React from "react";
import { Link } from "react-router-dom";
import "../../../assets/styles/footer/Footer.css";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Text,
} from "@chakra-ui/react";

const FooterList = ({ head, subhead }) => {
  return (
    <>
      <div className="footer_section">
        <p className="footer_list_heading">{head}</p>
        {subhead?.map((el, index) => (
          <Link
            to={`/${el}`}
            onClick={() =>
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
              })
            }
          >
            <p key={index} className="footer_list_sub_heading">
              {el}
            </p>
          </Link>
        ))}
      </div>

      <div className="footer-for-mobile">
        <Accordion allowToggle>
          <AccordionItem border="none">
            <h2>
              <AccordionButton>
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  borderLeft="1px solid #F9AC3C"
                  padding="12px"
                  color="#ffffff"
                >
                  <Text fontSize="20px" marginLeft="18px">
                    {head}
                  </Text>
                </Box>
                <AccordionIcon color="#ffffff" />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} marginLeft="30px">
              {subhead?.map((el, index) => (
                <Link
                  to={`/${el}`}
                  onClick={() =>
                    window.scrollTo({
                      top: 0,
                      left: 0,
                      behavior: "smooth",
                    })
                  }
                >
                  <p key={index} className="footer_list_sub_heading">
                    {el}
                  </p>
                </Link>
              ))}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
    </>
  );
};

export default FooterList;
