import { Box } from "@chakra-ui/react"
import { Profile, TitleSection } from "../../../shared/building-blocks"

export const LeadershipSection = ({ aboutusData }) => {
    return (
        <Box className="leadership-container">
            <TitleSection aboutusData={aboutusData} />
            <Box className="cards-container">
                {aboutusData.leaderSection.cards.map((card) => {
                    return (
                        <Profile card={card} />
                    )
                })}
            </Box>
        </Box>
    )
} 