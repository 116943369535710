import React from "react";
import Herosections from "../../shared/building-blocks/molecules/Product-Components/Herosections";
import Footer from "../../shared/building-blocks/organisms/Footer";
import Navbar from "../../shared/building-blocks/organisms/Navbar";
import { OmnichannelData } from "../../adapters/omnichannel-solution/omnichannel-solution";

import Gallary from "../../shared/building-blocks/molecules/Product-Components/Gallary";
import OtherFeatures from "../../shared/building-blocks/molecules/Product-Components/AllFeatchers";
import LearnMoreSolutions from "../../shared/building-blocks/molecules/Solutions-components/LearnMoreSolutions";


const OmnichannelPage = () => {
  return (
    <>
       <Navbar />

<Herosections data={OmnichannelData} name="OMNI" />

{OmnichannelData?.Gallary.map((item, index) => (
  <Gallary data={item} name={"OMNI"} type={index % 2 == 0 ? "TI" : "IT"} />
))}

<OtherFeatures data={OmnichannelData} name={"OMNI"} />
<LearnMoreSolutions data={OmnichannelData} name={"OMNI"} />
<Footer />
    </>
  )
}

export default OmnichannelPage;