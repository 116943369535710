import { Box, Button, Image, Text } from "@chakra-ui/react"

// styles 
import "./profile.css"
// react icons 
import { BsLinkedin } from 'react-icons/bs';
import { FiArrowUpRight } from 'react-icons/fi';
import { Link } from "react-router-dom";
import { Linkdin } from "../../../../assets/images/Index";
export const Profile = ({ card }) => {

    return (
        <Box className="profile-container">
            <Image src={card.profileImg} />
            <Box className="details">
                <Box>
                    <Text>{card.name}</Text>
                    <a href={card.linkedin}><img src={Linkdin}/></a>
                </Box>
                <Text>{card.designation}</Text>
                {/* <a href={card.linkedin}><button><BsLinkedin color="white" /> Linkedin <FiArrowUpRight color="white" /> </button></a> */}
            </Box>
            <Text className="description">{card.about}</Text>
            <Text className="read-more"
                onClick={() =>
                    window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: "smooth",
                    })
                }><Link to={`/${card.title}`}>Read more <FiArrowUpRight /></Link></Text>
        </Box>

    )
}