import {
    HexagonImg,ArrowRightUpLine,B2cSolutionIcon1,B2cSolutionIcon2,B2cSolutionIcon3,B2cSolutionIcon4,
    D2cMainImg,D2cGalleryImg1,D2cGalleryImg2,D2cGalleryImg3,D2cGalleryIcon1,D2cGalleryIcon2,D2cGalleryIcon3,
    D2cFeatcherIcon1,D2cFeatcherIcon2,D2cFeatcherIcon3
  } from "../../assets/images/Index";
  
  export const D2cData = {
    // hero section
    HeroSection: {
      left: {
        title: "D2C Commerce",
        heading: "Own complete customer experience",
        discription:
          "Curate End-to-end brand experience from product discovery to customer support using a powerful commerce platform.",
          buttonText: "Request a demo",
        arrowUpIcon: ArrowRightUpLine,
      },
  
      right: {
        imgUrl: D2cMainImg,
      },
    },
  
    Gallary: [
      {
        left: {
          HexagonImg: HexagonImg,
          icon: D2cGalleryIcon1,
          heading: "Deliver personalized services",
          discription:
            "Leverage reliable consumer insights from multiple touchpoints and personalize offerings based on customer preferences.",
        },
        right: {
          imgUrl: D2cGalleryImg1,
        },
      },
      {
        left: {
          HexagonImg: HexagonImg,
          icon: D2cGalleryIcon2,
          heading: "Increase brand recognition",
          discription:
            "Improve mindshare by having an omnichannel presence and providing a cohesive and consistent brand experience.",
        },
        right: {
          imgUrl: D2cGalleryImg2,
        },
      },
      {
        left: {
          HexagonImg: HexagonImg,
          icon: D2cGalleryIcon3,
          heading: "Listen to customers, get actionable feedback",
          discription:
            "Gather actionable feedback to understand customers, improve product and services offered, and attract new customers through positive reviews.",
        },
        right: {
          imgUrl: D2cGalleryImg3,
        },
      },
    ],
  
    otherFeatures: {
      mainHeading: "Additional features",
      HexagonImg: HexagonImg,
      allFeatures: [
        {
          icon: D2cFeatcherIcon1,
          heading: "Deliver through flexible fulfillment options",
          description:
            "Provide multiple shipping methods like ship from store,store pickup, standard delivery, express delivery and international delivery.",
        },
        {
          icon: D2cFeatcherIcon2,
          heading: "Make the most of your marketing budget",
          description:
            "Reach relevant audience and optimize costs using powerful customer segmentation engine and campaign push notifications.",
        },
        {
          icon: D2cFeatcherIcon3,
          heading: "Ensure product availability",
          description:
            "Make use of real - time inventory management to keep track of stock levels and respond to changes in demand.",
        },
      ],
    },
  
    Featcherheading: "Explore more Solutions",
    LearnMoreSolution: [
      {
        Solution: "Marketplace solution",
        FeatcherLeftHead: "Intermediate between multiple buyers and sellers",
        OrderManagementImg: B2cSolutionIcon1,
        ArrowRightUpLine: ArrowRightUpLine,
      },
      {
        Solution: "D2C Commerce",
        FeatcherLeftHead: "Sell products directly to customers without intermediaries",
        OrderManagementImg: B2cSolutionIcon2,
        ArrowRightUpLine: ArrowRightUpLine,
      },
      {
        Solution: "Omnichannel solution",
        FeatcherLeftHead: "Sell products seamlessly through multiple channels",
        OrderManagementImg: B2cSolutionIcon3,
        ArrowRightUpLine: ArrowRightUpLine,
      },
      {
        Solution: "B2B Commerce",
        FeatcherLeftHead: "Sell products or services to other companies",
        OrderManagementImg: B2cSolutionIcon4,
        ArrowRightUpLine: ArrowRightUpLine,
      },
    ],
  };
  