import React from "react";
import Navbar from "../../../shared/building-blocks/organisms/Navbar";
import Footer from "../../../shared/building-blocks/organisms/Footer";
import Herosections from "../../../shared/building-blocks/molecules/Product-Components/Herosections";
import { ProductInformationData } from "../../../adapters/product-Information-management/Product-Information";
import Gallary from "../../../shared/building-blocks/molecules/Product-Components/Gallary";
import OtherFeatures from "../../../shared/building-blocks/molecules/Product-Components/AllFeatchers";
import MoreFeaturesSection from "../../../shared/building-blocks/molecules/Product-Components/MoreFeatures";
const ProductInformationManagement = () => {
  return (
    <>
      <Navbar />

      <Herosections data={ProductInformationData} name="PIM" />
      {ProductInformationData?.Gallary.map((item, index) => (
        <Gallary data={item} name={"PIM"} type={index % 2 == 0 ? "TI" : "IT"} />
      ))}

      <OtherFeatures data={ProductInformationData} name={"PIM"} />
      <MoreFeaturesSection data={ProductInformationData} name={"PIM"} />
      <Footer />
    </>
  );
};

export default ProductInformationManagement;