import React from 'react'
import {Link} from "react-router-dom"
import "../../../../assets/styles/navbar/Navbar.css"
import { NavbarData } from '../../../../adapters/Index';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Text,
  Button,
} from '@chakra-ui/react'
const DropdownMenu = () => {
  console.log(NavbarData)
  return (
    <div className='mobile-drop-down'>
      <Accordion isFocusable={false} allowToggle >
        {Object.keys(NavbarData.menu).map((menuItem,index) => {
          if(Object.keys(NavbarData.menu).length-1!==index) return (
            <Box key={index}>
              <AccordionItem border="none">
                <h2>
                  <AccordionButton>
                    <Box as="span" color="#191F23" fontWeight="650" fontSize={"1rem"} flex='1' textAlign='left'>
                      {menuItem}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  { NavbarData.menu[menuItem].map((ListItem,index)=>{
                    return (
                      <Box fontSize={".8rem"} mb={'1rem'}><Link to={`/${ListItem}`}><Text key={index} textAlign="left">{ListItem}</Text></Link></Box>)
                  }) }
                </AccordionPanel>
              </AccordionItem>
            </Box>
          )
          else return <Box pl="5%" pb="5%" pt="2%"><Text fontWeight={"650"}><Link to="/About Us">{menuItem}</Link></Text></Box>
        })}

      </Accordion>
      <center><Button m="auto" backgroundColor="rgba(82, 115, 239, 0.2)" color="#52636F"><Link to="/Contact">Contact us</Link></Button></center>
    </div>
  )
}

export default DropdownMenu