import React, { useEffect, useState } from "react";
import { TermAndServiceData } from "../../adapters/Terms-and-service/Term-and-service";
import "../../assets/styles/term-and-service/TermAndService.css"
import { IoIosArrowDown } from 'react-icons/io';
import Footer from "../../shared/building-blocks/organisms/Footer";
import Navbar from "../../shared/building-blocks/organisms/Navbar";

const TermAndService = () => {
  const [id, setId] = useState(0);
  const [name,setName] = useState(TermAndServiceData.Title)

  const handleSetId = (objId) => {
    if (id !== objId) {
      setId(objId);
    } else {
      setId(0);
    }
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);


  return (
     <>
      
      <Navbar />

        <div className="mobile-link-box">

        <p className="TS-left-head">{TermAndServiceData.left_head}</p>
         
              <select className="TS-left-Link-mobile"  onChange={(e)=> setName(e.target.value)}>
              {TermAndServiceData?.left?.map((el) => (
                 <option className="TS-left-Link-mobile" value={el}>{el}</option>
               ))}
              </select>
        </div>

      <p className="TS-Title">{name}</p>

      <div className="TS-container">
        <div className="TS-left-container">
          <p className="TS-left-head">{TermAndServiceData.left_head}</p>
          {TermAndServiceData?.left?.map((el) => (
            <p className="TS-left-Link"  onClick={()=> setName(el)}>{el}</p>
          ))}
        </div>

        <div className="TS-right-container">
          <p className="TS-r-head">{TermAndServiceData?.right_head}</p>
           <div className="TS-r-subhead-box">
           {TermAndServiceData?.right_subhead.map((el) => (
            <p className="TS-r-subhead">{el}</p>
          ))}
           </div>
        
          <div className="TS-r-info-Box">
            {TermAndServiceData?.termAndService?.map((item, index) => {
              return (
                <div
                  className="TS-r-info-cont"
                  key={index}
                >
                  <div className="">
                    {/* zzz */}
                    <p className="TS-r-info-question">{item.question}</p>
                    <p className="TS-r-info-details">{item.details}</p>
                    
                     <div className="TS-r-info-readbox" onClick={() => handleSetId(item.id)}>
                         <p className="TS-r-info-readText">Read More</p> <IoIosArrowDown />
                     </div>

                  </div>

                  {id === item.id
                    ? item.answer.map((item, index) => {
                        return (
                          <div className="privacyPolicyAnswers" key={index}>
                            <p>{item.text1 ? item.text1 : ""}</p>
                            <br />
                            <p>{item.text2 ? item.text2 : ""}</p>
                            <br />
                            <p>{item.text3 ? item.text3 : ""}</p>
                            <br />
                            <p>{item.text4 ? item.text4 : ""}</p>
                            <br />
                            <p>{item.text5 ? item.text5 : ""}</p>
                            <p>{item.text6 ? item.text6 : ""}</p>
                            <p>{item.text7 ? item.text7 : ""}</p>
                            <p>{item.text8 ? item.text8 : ""}</p>
                          </div>
                        );
                      })
                    : null}
                </div>
              );
            })}
          </div>
        </div>
      </div>


      <Footer />
    </>
  );
};

export default TermAndService;
