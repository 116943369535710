import { Box } from "@chakra-ui/react"
// styles 
import "../../../../assets/styles/building-blocks/organisms/feature-section.css";
import { FeatureContent, FeatureImage, SpecialContent } from "../../atoms";
export const FeatureSection = ({ featureData, icon }) => {
    return (
        <Box className="feature-section-container">
            {featureData.map((section, index) => {
                if (index % 2 !== 0) {
                    return (
                        <Box className="feature-left-section" key={index}>
                            <FeatureImage src={section.img} />
                            <FeatureContent icon={icon} feature={section} text={section} />
                            {/* <SpecialContent text={section}/> */}
                        </Box>
                    )
                } else return (
                    <Box className="feature-right-section" key={index}>
                        {/* <SpecialContent text={section}/> */}
                        <FeatureContent icon={icon} feature={section} text={section} />
                        <FeatureImage src={section.img} />
                    </Box>
                )
            })}
        </Box>
    )
}