import { Box, Text } from "@chakra-ui/react"
// styles 
import "./title-section.css";
export const TitleSection = ({aboutusData})=>{
    console.log(aboutusData);
    return (
        <Box className="title-content-container">
            <Text>{aboutusData.leaderSection.title}</Text>
            <Text>{aboutusData.leaderSection.heading}</Text>
        </Box>
    )
}