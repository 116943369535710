import CommingSooner from "../../assets/images/comming-soon/Coming-Soon.gif"
import Footer from "../../shared/building-blocks/organisms/Footer"
import Navbar from "../../shared/building-blocks/organisms/Navbar"
import "../../assets/styles/comming-soon/comming-soon.css"

const CommingSoon = ()=>{
    return (
        <div style={{width: "100%"}}>
            <Navbar/>
            <img className="soon-cont" src={CommingSooner} alt="Comming Soon" />
            <Footer/>
        </div>
    )
}

export default CommingSoon