import {DCFIcon1,DCFIcon3,HexagonImg, DigitalCommerceImg ,BoosterIconImg,ArrowRightUpLine ,StoreProfitCalculationImg ,FemaleImg ,ClothIcon ,OrderStatusImg ,RelationShipIcon,SellImg,WorldIcon ,FrontEndBuilder,OrderManagementImg,MsgIcon, FeatcherLeftImg, FeatcherRightImg} from "../../assets/images/Index";

export const DigitalCommerceData = {

    HexagonImg : HexagonImg, 
    HeroSection : {
       left : {
        title:"Digital Commerce Platform",
        heading :"Multiple business models, one platform",
        discription:"Choose an innovative commerce solution tailor-made to your requirements, whether it be B2C, B2B,marketplace, or a combination of these.",
        buttonText: "Request a demo",
        arrowUpIcon :ArrowRightUpLine,
       },

       right : {
          imgUrl :DigitalCommerceImg
       }
    },

    Gallary :[
        {
            left :{
                HexagonImg : HexagonImg,
                icon : BoosterIconImg,
                heading:"Create exciting experiences instantly",
                discription : "Reduce your time to market with industry-specific, delivery-ready solutions.",
            },
            right :{
                imgUrl : StoreProfitCalculationImg,
            }
        },
        {
            left :{
                HexagonImg : HexagonImg,
                icon : ClothIcon,
                heading: "Boost search relevance",
                discription : "Improve search results with a smart and intuitive search engine that understands user queries.",
            },
            right :{
                imgUrl : FemaleImg,
            }
        },
        {
            left :{
                HexagonImg : HexagonImg,
                icon : RelationShipIcon,
                heading: "Build strong relationships with customers",
                discription :"Personalize promotions by creating target groups to increase average value and win customer loyalty.",
            },
            right :{
                imgUrl : OrderStatusImg,
            }
        },
        {
            left :{
                HexagonImg : HexagonImg,
                icon : WorldIcon,
                heading: "Sell from anywhere to everywhere",
                discription :"Seamlessly run multi-stores across multiple regions, each with different currencies and language combinations.",
            },
            right :{
                imgUrl : SellImg,
            }
        },
    ],


  // FEATCHER SETIONS
    otherFeatures: {
        mainHeading: "Additional features",
        HexagonImg :HexagonImg,
        allFeatures:[
            {
                icon: DCFIcon1,
                heading: "Secure access to information",
                description: "Mitigate security risks and data thefts by defining and controlling access to various commerce modules.",   
            },{
                icon: MsgIcon,
                heading: "Act on custumer feedback",
                description: "Increase custumer satisfaction by effectively collecting, organizing and analyzing custumer reveiews.",   
            },{
                icon: DCFIcon3,
                heading: "Operate from fingertips",
                description: "Allow backend managers to take on day to day activities from anywhere, anytime on a smartphone with mobile ready commerce management features.",   
            }
        ]
    },
     
    Featcherheading : "Explore More",
    LearnMoreFeature :[
        {
            Plateform : "PLATFORM", 
            FeatcherLeftHead :"Order Management System",
            OrderManagementImg : OrderManagementImg,
            ArrowRightUpLine :ArrowRightUpLine,
        },
        {
            Plateform : "PLATFORM", 
            FeatcherLeftHead :"Front End Experience Builder",
            OrderManagementImg : FrontEndBuilder,
            ArrowRightUpLine :ArrowRightUpLine,
        },
        {
            Plateform : "PLATFORM", 
            FeatcherLeftHead :"Catalog Management",
            OrderManagementImg : FeatcherLeftImg,
            ArrowRightUpLine :ArrowRightUpLine,
        }, 
        {
            Plateform : "PLATFORM", 
            FeatcherLeftHead :"Product Information Management",
            OrderManagementImg : FeatcherRightImg,
            ArrowRightUpLine :ArrowRightUpLine,
        }
    ]

}