import {Instagram, Facebook, Linkedin, Pointer, FooterBackground } from "../../assets/images/Index";


export const FooterData = {
    demo:{
        title: "LET'S TRANSFORM",
        heading: "Ready to run your world of commerce with Honebi?",
        button: "Request a demo",
        Pointer: Pointer
    },
    icon:{
        facebook : Facebook,
        instagram : Instagram,
        linkdin : Linkedin
    },
    menu: {
        "Products":["Digital Commerce Platform", "Front-End Experience Builder(CMS)", "Product Information Management", "Catalog Management", "Order Management System",],
        "Solutions":["B2C Commerce","B2B Commerce","D2C Commerce","Omnichannel Solution","Marketplace Solutions"],
        // "Tools":["Sales Assistance Solution","Customer 360 Solution","In Store VM Solution","Van POS Solution"],
        "Company":["About Us","Press & Media","Investor Relations","Careers","Contact Us"],
        // "Editions":["Honebi","Honebi Enterprise"]
    },
    links:[ "Privacy policy","Terms and Conditions","Compliance","Security","Site Map"],
    FooterBackground : FooterBackground
}