import React from 'react'
import Footer from '../../shared/building-blocks/organisms/Footer';
import Navbar from "../../shared/building-blocks/organisms/Navbar";
import FeaturesSection from './FeaturesSection';
import HeroSection from './HeroSection';
import NewsLetter from './newsletter';
import Stories from './Stories';
import UseCases from './use-cases';

const HomePage = () => {
  return (
    <div>
      <Navbar />
      <HeroSection/>
      <FeaturesSection/>
      <UseCases/>
      <Stories/>
      <Footer /> 
    </div>
  )
}

export default HomePage