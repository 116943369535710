import React from 'react'
import "../../../../assets/styles/navbar/Navbar.css"
import { Link } from "react-router-dom";
const NavbarDropDown = ({menu,setShowDropDown}) => {
  return (
    <div className='dropDown-section' onMouseLeave={()=>setShowDropDown(false)}>
        <div className='menu-section'>
            { menu.map((menuItem)=>{
                return (
                    <p onClick={()=>setShowDropDown(false)}><Link to={`/${menuItem}`}>{menuItem}</Link></p>
                )
            }) }
        </div>
    </div>
  )
}

export default NavbarDropDown