import { Box } from "@chakra-ui/react"
import {careersAdapter} from "../../adapters/Index"; 
import Footer from "../../shared/building-blocks/organisms/Footer";
import Navbar from "../../shared/building-blocks/organisms/Navbar";
import { HeroSection, SpecialSection, FeatureSection, BenefitsSection } from "../../shared/building-blocks/organisms/reusable-organisms";
export const Careerpage = ()=>{
    return(
        <>
            <Navbar/>
                <HeroSection heroData={careersAdapter}/>
                <SpecialSection specialData={careersAdapter.specialSection}/>
                <FeatureSection featureData={careersAdapter.featureSection}/>
                {/* <BenefitsSection benefitsData={careersAdapter.benefitsSection}/> */}
            <Footer/>
        </>
    )
}