import cofounder from "../../assets/images/about-us/SaiProfile.png";
import founder from "../../assets/images/about-us/MadanProfile.png";

export const Cofounder = {
    profileImg: cofounder,
    name: "",
    designation: "Co Founder & CTO",
    linkedin: "",
    about: "",
    extension: ``
}