import {
  HexagonImg,
  ArrowRightUpLine,
  PimMainImg,
  PIMGallaryImg1,
  PIMGallaryImg2,
  PIMGallaryImg3,
  PIMGallaryIcon1,
  PIMGallaryIcon2,
  PIMGallaryIcon3,
  PIMFeaturesIcon1,
  PIMFeaturesIcon2,
  PIMFeaturesIcon3,
  OrderManagementImg,
  FrontEndBuilder,
  FeatcherLeftImg,
  FeatcherRightImg,
} from "../../assets/images/Index";

export const ProductInformationData = {
  HeroSection: {
    left: {
      title: "Product Information Management",
      heading: "Achieve Data Accuracy and Consistency",
      discription:
        "Ensure all product information is up-to-date, consistent, and accurate across all channels, reducing errors and discrepancies.",
        buttonText : "Request a demo",
      arrowUpIcon: ArrowRightUpLine,
    },

    right: {
      imgUrl: PimMainImg,
    },
  },

  Gallary: [
    {
      left: {
        HexagonImg: HexagonImg,
        icon: PIMGallaryIcon1,
        heading: "Increase information efficiency",
        discription:
          "Increase efficiency and reduce costs by decreasing the time and resources needed to handle product data and automating associated tasks.",
      },
      right: {
        imgUrl: PIMGallaryImg1,
      },
    },
    {
      left: {
        HexagonImg: HexagonImg,
        icon: PIMGallaryIcon2,
        heading: "Eliminate redundancy",
        discription:
          "Efficiently upload and manage extensive volumes of product information, significantly reducing the need for manual individual entries.",
      },
      right: {
        imgUrl: PIMGallaryImg2,
      },
    },
    {
      left: {
        HexagonImg: HexagonImg,
        icon: PIMGallaryIcon3,
        heading: "Showcase rich media",
        discription:
          "Help customers better understand the product and its features with images and videos, increasing the likelihood of a purchase.",
      },
      right: {
        imgUrl: PIMGallaryImg3,
      },
    },
  ],

  otherFeatures: {
    mainHeading: "Additional features",
    HexagonImg: HexagonImg,
    allFeatures: [
      {
        icon: PIMFeaturesIcon1,
        heading: "Boost conversion rates",
        description:
          "Give customers more options to choose from, by configuring similar product increasing the chances making a purchase.",
      },
      {
        icon: PIMFeaturesIcon2,
        heading: "Optimize website search",
        description:
          "Use multi-language keyworks in products to cater content for different languages and regions,making it easier for customers to find products or services.",
      },
      {
        icon: PIMFeaturesIcon3,
        heading: "Simplify shopping experience",
        description:
          "Group all variants under a single product listing, making it easier for custumers to find what they are looking for and simplifies the shopping experience.",
      },
    ],
  },

  Featcherheading : "Explore More",
  LearnMoreFeature :[
      {
          Plateform : "PLATFORM", 
          FeatcherLeftHead :"Order Management System",
          OrderManagementImg : OrderManagementImg,
          ArrowRightUpLine :ArrowRightUpLine,
      },
      {
          Plateform : "PLATFORM", 
          FeatcherLeftHead :"Front End Experience Builder",
          OrderManagementImg : FrontEndBuilder,
          ArrowRightUpLine :ArrowRightUpLine,
      },
      {
          Plateform : "PLATFORM", 
          FeatcherLeftHead :"Catalog Management",
          OrderManagementImg : FeatcherLeftImg,
          ArrowRightUpLine :ArrowRightUpLine,
      }, 
      {
          Plateform : "PLATFORM", 
          FeatcherLeftHead :"Product Information Management",
          OrderManagementImg : FeatcherRightImg,
          ArrowRightUpLine :ArrowRightUpLine,
      }
  ]

  
};
