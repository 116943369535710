import {
    HexagonImg,
    ArrowRightUpLine,
    B2cMianImg,B2cGallaryImg1,B2cGallaryImg2,B2cGallaryImg3,B2cGallaryIcon1,B2cGallaryIcon2,B2cGallaryIcon3,B2cFImg1,B2cFImg2,B2cFImg3,B2cSolutionIcon1,B2cSolutionIcon2,B2cSolutionIcon3,
    B2cSolutionIcon4, texture
  } from "../../assets/images/Index";
  
  export const B2cData = {
    // hero section
    HeroSection: {
      left: {
        title: "B2C Commerce",
        heading: "Explore new avenues",
        discription:
          "Start online-first or complement offline businesses with innovative ways to sell online.",
        buttonText: "Request a demo",
        arrowUpIcon: ArrowRightUpLine,
      },
  
      right: {
        imgUrl: B2cMianImg,
      },
    },
  
    Gallary: [
      {
        left: {
          HexagonImg: HexagonImg,
          icon: B2cGallaryIcon1,
          heading: "Shrink time to market",
          discription:
            "Go live faster by deploying a full-fledged commerce solution in a matter of days.",
        },
        right: {
          imgUrl: B2cGallaryImg1,
        },
      },
      {
          left :{
              HexagonImg : HexagonImg,
              icon : B2cGallaryIcon2,
              heading: "Adopt flexible fulfillment approach",
              discription :"Enable different fulfillment options for customers - buy online, pickup in-store; ship from store or scheduled delivery covering all kinds of customer needs.",
          },
          right :{
              imgUrl :B2cGallaryImg2,
          }
      },
      {
          left: {
            HexagonImg: HexagonImg,
            icon: B2cGallaryIcon3,
            heading: "Broaden boundaries",
            discription:
              "Push geographical limits with truly global stores, with multi-currency, multi-tax, multi-language capabilities.",
          },
          right: {
            imgUrl: B2cGallaryImg3,
          },
        },
    ],
  
    otherFeatures: {
      mainHeading: "Additional features",
      HexagonImg: HexagonImg,
      allFeatures: [
        {
          icon: B2cFImg1,
          heading: "Cut dependencies",
          description:
            "“Get an exhaustive library of in-built tools and reduce dependencies on 3rd party sources”.",
        },
        {
          icon: B2cFImg2,
          heading: "Leverage accelerated checkouts",
          description:
            "Convert leads into buyers with an ultra fast, intelligent search engine that delivers relevant results thereby enriching the shopping experience.",
        },
        {
          icon: B2cFImg3,
          heading: "Sweeten customer experience",
          description:
            "Listen and learn what customers are looking for and show them what they want with utmost speed and intelligence.",
        },
      ],
      texture
    },
    
    
    Featcherheading : "Explore more Solutions",
    LearnMoreSolution :[
      {
          Solution : "Marketplace solution", 
          FeatcherLeftHead :"Intermediate between multiple buyers and sellers",
          OrderManagementImg : B2cSolutionIcon1,
          ArrowRightUpLine :ArrowRightUpLine,
      },
      {
          Solution : "Marketplace solution",
          FeatcherLeftHead :"Intermediate between multiple buyers and sellers",
          OrderManagementImg : B2cSolutionIcon2,
          ArrowRightUpLine :ArrowRightUpLine,
      },
      {
        Solution : "Omnichannel solution",
          FeatcherLeftHead :"Sell products seamlessly through multiple channels",
          OrderManagementImg : B2cSolutionIcon3,
          ArrowRightUpLine :ArrowRightUpLine,
      }, 
      {
        Solution : "B2B Commerce",
          FeatcherLeftHead :"Sell products or services to other companies",
          OrderManagementImg : B2cSolutionIcon4,
          ArrowRightUpLine :ArrowRightUpLine,
      }
  ]
  
  };
  