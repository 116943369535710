import { Box } from "@chakra-ui/react"
import "../../../../assets/styles/building-blocks/organisms/hero-section.css";
import { HeroButton, HeroDescription, HeroHeading, HeroImage, HeroTitle } from "../../atoms";
export const HeroSection = ({heroData})=>{
    return (
        <Box className="hero-section-container">
            <Box className="left-section">
                <HeroTitle title={heroData.heroSection.title}/>
                <HeroHeading color={""} heading1={heroData.heroSection.heading1} heading2={heroData.heroSection.heading2}/>
                <HeroDescription description={heroData.heroSection.description}/>
                <HeroButton text={heroData.heroSection.button}/>
            </Box>
            <Box className="right-section">
                <HeroImage src={heroData.heroSection.mainImg}/>
            </Box>
        </Box>
    )
}