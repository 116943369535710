import {
  HexagonImg,
  ArrowRightUpLine,
  CatalogImg1,
  TailorImg4,
  SaleImg,
  FeatcherImgLeft1,
  FeatcherImgMid2,
  FeatcherImgRight3,
  UpsellingIcon1,
  UpsellingImg2,
  sellIcon2,
  TailorIcon3,
  OrderManagementImg,
  FeatcherLeftImg,
  FeatcherRightImg,
  FrontEndBuilder,
} from "../../assets/images/Index";

export const CatelogManagementData = {
  // hero section
  HeroSection: {
    left: {
      title: "Catalog Management",
      heading: "Compose stunning visual merchandising",
      discription:
        "Categorize and configure how products are displayed to customers to improve their shopping experience, making items easily accessible and optimizing sales.",
      buttonText: "Request a demo",
      arrowUpIcon: ArrowRightUpLine,
    },

    right: {
      imgUrl: CatalogImg1,
    },
  },

  Gallary: [
    {
      left: {
        HexagonImg: HexagonImg,
        icon: UpsellingIcon1,
        heading: "Promote upselling and cross selling",
        discription:
          "Relate products to each other, suggest similar and frequently-purchased product combinations to increase the average order value.",
      },
      right: {
        imgUrl: UpsellingImg2,
      },
    },
    {
      left: {
        HexagonImg: HexagonImg,
        icon: sellIcon2,
        heading: "Create campaign catalogs",
        discription:
          "Encourage customers to make purchases during a campaign by grouping seasonal products.",
      },
      right: {
        imgUrl: SaleImg,
      },
    },
    {
      left: {
        HexagonImg: HexagonImg,
        icon: TailorIcon3,
        heading: "Tailor pricing strategy",
        discription:
          "Adjust pricing to local markets by maintaining different prices for the same product across multiple stores and channels, thus maximizing sales and profits.",
      },
      right: {
        imgUrl: TailorImg4,
      },
    },
  ],

  otherFeatures: {
    mainHeading: "Additional features",
    HexagonImg: HexagonImg,
    allFeatures: [
      {
        icon: FeatcherImgLeft1,
        heading: "Centralized cataloging mechanism",
        description:
          "Synchronize the store catalog with the ERP system for automatic updates, providing a more efficient a streamlined process for managing product pricing and inventory.",
      },
      {
        icon: FeatcherImgMid2,
        heading: "Reduce maintenance efforts",
        description:
          "Update catalog information in bulk by using an import tool and save a significant amount of time compared to manually entering individual product's pricing.",
      },
      {
        icon: FeatcherImgRight3,
        heading: "Minimize order rejections",
        description:
          "Improve customer satisfaction and prevent overselling by reserving stock as soon as the order is accepted.",
      },
    ],
  },

  Featcherheading: "Explore More",
  LearnMoreFeature: [
    {
      Plateform: "PLATFORM",
      FeatcherLeftHead: "Order Management System",
      OrderManagementImg: OrderManagementImg,
      ArrowRightUpLine: ArrowRightUpLine,
    },
    {
      Plateform: "PLATFORM",
      FeatcherLeftHead: "Front End Experience Builder",
      OrderManagementImg: FrontEndBuilder,
      ArrowRightUpLine: ArrowRightUpLine,
    },
    {
      Plateform: "PLATFORM",
      FeatcherLeftHead: "Catalog Management",
      OrderManagementImg: FeatcherLeftImg,
      ArrowRightUpLine: ArrowRightUpLine,
    },
    {
      Plateform: "PLATFORM",
      FeatcherLeftHead: "Product Information Management",
      OrderManagementImg: FeatcherRightImg,
      ArrowRightUpLine: ArrowRightUpLine,
    },
  ],
};
