import { KiranaKing, Ratnadeep, Pointer,HexagonIcon, HexagonFill } from "../../assets/images/Index"
export const StoriesData = {
    title: "OUR STORIES",
    heading: "Driving a tailored digital commerce experience for our customers",
    stories:[
        {
            id:1,
            storyImg: Ratnadeep,
            title: "Ratnadeep",
            description: "“We are impressed by the level of support received from Honebi. They have been responsive, knowledgeable, and proactive in ensuring our business goals are met during our retail commerce transformation journey. We highly recommend Honebi to any retail business looking to improve their online sales and enhance their customer experience. They are truly a game-changer in the e-commerce space”.",
            details: ""
        },
        {
            id:2,
            storyImg: KiranaKing,
            title: "KiranaKing",
            description: "“The platform’s ability to handle a large volume of transactions, as well as its seamless integration capabilities, has made it incredibly easy for our customers to purchase from us online. Honebi’s intuitive design and user-friendly interface has also made it simple for us to manage our online store, update products, and track sales”.",
            details: ""
        }
    ],
    Pointer:Pointer,
    HexagonIcon, HexagonFill
}